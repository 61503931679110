// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import AdminHome from './components/AdminHome';
import AdminLogin from './components/AdminLogin';
import NotFound from './components/NotFound';
import About from './components/About';
import Catalogue from './components/Catalogue';
import Cart from './components/Cart';
import AdminProductPage from './components/AdminProductPage';
import Contact from './components/Contact';
import AdminSettings from './components/AdminSettings';
import ProductDetail from './components/ProductDetail';
import Checkout from './components/Checkout';
import { UserProvider } from './UserContext'; // Import UserProvider
import AdminSubmissions from './components/AdminSubmissions';
import Success from './components/Success.jsx';
import ProcessingOrder from './components/Process';
import AdminOrders from './components/AdminOrders.jsx';
import AdminReviews from './components/AdminReviews.jsx';
const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/home" element={<AdminHome />} />
          <Route path="/about" element={<About />} />
          <Route path="/catalogue" element={<Catalogue />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/admin/products" element={<AdminProductPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path='processing' element={<ProcessingOrder />}/>
          <Route path='/success' element={<Success />} />
          <Route path="/products/:productName" element={<ProductDetail />} />
          <Route path="/admin/settings" element={<AdminSettings />} />
          <Route path="/admin/contacts" element={<AdminSubmissions />} />
          <Route path="/admin/orders" element={<AdminOrders />} />
          <Route path="/admin/reviews" element={<AdminReviews />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
