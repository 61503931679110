// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { API_BASE_URL } from '../config';

// const AdminReviewsPage = () => {
//     const [products, setProducts] = useState([]);
//     const [selectedProduct, setSelectedProduct] = useState(null);
//     const [reviews, setReviews] = useState([]);
//     const [loading, setLoading] = useState(false);

//     useEffect(() => {
//         // Fetch all products
//         axios
//             .get(`${API_BASE_URL}/products`)
//             .then((response) => setProducts(response.data))
//             .catch((error) => console.error('Error fetching products:', error));
//     }, []);

//     const fetchReviews = (productIdentifier) => {
//         // Toggle reviews: if the clicked product is already selected, close it
//         if (selectedProduct === productIdentifier) {
//             setSelectedProduct(null);
//             setReviews([]);
//             return;
//         }

//         // Otherwise, fetch reviews for the clicked product
//         setLoading(true);
//         axios
//             .get(`${API_BASE_URL}/products/${productIdentifier}/reviews`)
//             .then((response) => {
//                 setSelectedProduct(productIdentifier);
//                 setReviews(response.data);
//                 setLoading(false);
//             })
//             .catch((error) => {
//                 console.error('Error fetching reviews:', error);
//                 setLoading(false);
//             });
//     };

//     const deleteReview = (reviewId) => {
//         if (window.confirm('Are you sure you want to delete this review?')) {
//             axios
//                 .delete(`${API_BASE_URL}/reviews/${reviewId}`, {
//                     headers: {
//                         Authorization: `Bearer ${localStorage.getItem('token')}`, // Include admin token
//                     },
//                 })
//                 .then(() => {
//                     // Refresh reviews after deletion
//                     setReviews(reviews.filter((review) => review.id !== reviewId));
//                 })
//                 .catch((error) => console.error('Error deleting review:', error));
//         }
//     };

//     return (
//         <div className="p-6">
//             <h1 className="text-2xl font-bold mb-4">Admin Review Management</h1>
//             <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
//                 <div>
//                     <h2 className="text-xl font-semibold mb-3">Products</h2>
//                     <ul className="space-y-2">
//                         {products.map((product) => (
//                             <li
//                                 key={product.id}
//                                 className={`p-4 border rounded-md cursor-pointer ${
//                                     selectedProduct === product.id ? 'bg-gray-200' : 'bg-white'
//                                 }`}
//                                 onClick={() => fetchReviews(product.id)}
//                             >
//                                 {product.name}
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//                 <div>
//                     <h2 className="text-xl font-semibold mb-3">
//                         Reviews for {selectedProduct ? `Product ${selectedProduct}` : 'Select a Product'}
//                     </h2>
//                     {loading ? (
//                         <p>Loading reviews...</p>
//                     ) : reviews.length > 0 ? (
//                         <ul className="space-y-4">
//                             {reviews.map((review) => (
//                                 <li
//                                     key={review.id}
//                                     className="p-4 border rounded-md bg-white shadow-sm flex justify-between"
//                                 >
//                                     <div>
//                                         <p className="font-semibold text-gray-800">{review.comment}</p>
//                                         <div className="flex items-center">
//                                             {[...Array(review.rating)].map((_, i) => (
//                                                 <span key={i} className="text-yellow-400">★</span>
//                                             ))}
//                                             {[...Array(5 - review.rating)].map((_, i) => (
//                                                 <span key={i} className="text-gray-300">★</span>
//                                             ))}
//                                         </div>
//                                         <p className="text-sm text-gray-500">
//                                             By {review.email} on{' '}
//                                             {new Date(review.created_at).toLocaleDateString()}
//                                         </p>
//                                     </div>
//                                     <button
//                                         onClick={() => deleteReview(review.id)}
//                                         className="ml-4 text-red-500 hover:underline"
//                                     >
//                                         Delete
//                                     </button>
//                                 </li>
//                             ))}
//                         </ul>
//                     ) : (
//                         <p>No reviews found for this product.</p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AdminReviewsPage;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';

const AdminReviewsPage = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Fetch all products
        axios
            .get(`${API_BASE_URL}/products`)
            .then((response) => setProducts(response.data))
            .catch((error) => console.error('Error fetching products:', error));
    }, []);

    const fetchReviews = (productIdentifier) => {
        setLoading(true);
        axios
            .get(`${API_BASE_URL}/products/${productIdentifier}/reviews`)
            .then((response) => {
                setSelectedProduct(
                    products.find((product) => product.id === productIdentifier)
                );
                setReviews(response.data);
                setLoading(false);
                setIsModalOpen(true);
            })
            .catch((error) => {
                console.error('Error fetching reviews:', error);
                setLoading(false);
            });
    };

    const deleteReview = (reviewId) => {
        if (window.confirm('Are you sure you want to delete this review?')) {
            axios
                .delete(`${API_BASE_URL}/reviews/${reviewId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then(() => {
                    setReviews(reviews.filter((review) => review.id !== reviewId));
                })
                .catch((error) => console.error('Error deleting review:', error));
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProduct(null);
        setReviews([]);
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Admin Review Management</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                    <h2 className="text-xl font-semibold mb-3">Products</h2>
                    <ul className="space-y-2">
                        {products.map((product) => (
                            <li
                                key={product.id}
                                className="p-4 border rounded-md cursor-pointer bg-white hover:bg-gray-100"
                                onClick={() => fetchReviews(product.id)}
                            >
                                {product.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white w-3/4 sm:w-1/2 p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-semibold mb-4">
                            Reviews for {selectedProduct?.name}
                        </h2>
                        <button
                            onClick={closeModal}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        >
                            ✖
                        </button>
                        {loading ? (
                            <p>Loading reviews...</p>
                        ) : reviews.length > 0 ? (
                            <ul className="space-y-4">
                                {reviews.map((review) => (
                                    <li
                                        key={review.id}
                                        className="p-4 border rounded-md bg-white shadow-sm flex justify-between"
                                    >
                                        <div>
                                            <p className="font-semibold text-gray-800">
                                                {review.comment}
                                            </p>
                                            <div className="flex items-center">
                                                {[...Array(review.rating)].map((_, i) => (
                                                    <span key={i} className="text-yellow-400">
                                                        ★
                                                    </span>
                                                ))}
                                                {[...Array(5 - review.rating)].map((_, i) => (
                                                    <span key={i} className="text-gray-300">
                                                        ★
                                                    </span>
                                                ))}
                                            </div>
                                            <p className="text-sm text-gray-500">
                                                By {review.email} on{' '}
                                                {new Date(
                                                    review.created_at
                                                ).toLocaleDateString()}
                                            </p>
                                        </div>
                                        <button
                                            onClick={() => deleteReview(review.id)}
                                            className="ml-4 text-red-500 hover:underline"
                                        >
                                            Delete
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No reviews found for this product.</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminReviewsPage;
