import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AdminReviews = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchReviews();
    }, []);

    const fetchReviews = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/api/admin/reviews'); // Adjust the endpoint as needed
            setReviews(response.data.reviews);
        } catch (error) {
            console.error("Error fetching reviews:", error);
        } finally {
            setLoading(false);
        }
    };

    const deleteReview = async (reviewId) => {
        if (!window.confirm("Are you sure you want to delete this review?")) return;

        try {
            await axios.delete(`/api/admin/reviews/${reviewId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`, // Include admin token
                },
            });
            setReviews(reviews.filter((review) => review.id !== reviewId)); // Remove deleted review from the state
            alert("Review deleted successfully!");
        } catch (error) {
            console.error("Error deleting review:", error);
            alert("Failed to delete the review. Please try again.");
        }
    };

    if (loading) return <p>Loading reviews...</p>;

    return (
        <div>
            <h2>Manage Reviews</h2>
            {reviews.length === 0 ? (
                <p>No reviews to display.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Rating</th>
                            <th>Comment</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reviews.map((review) => (
                            <tr key={review.id}>
                                <td>{review.email}</td>
                                <td>{review.rating}</td>
                                <td>{review.comment}</td>
                                <td>
                                    <button
                                        onClick={() => deleteReview(review.id)}
                                        style={{ color: "red" }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AdminReviews;
