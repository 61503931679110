import React, { useEffect, useState } from 'react';
import Header from './Header';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import { ShoppingCartIcon, StarIcon } from '@heroicons/react/24/solid';

const ProductDetail = () => {
    const { productId } = useParams();
    const { productName } = useParams();
    const [product, setProduct] = useState(null);
    const [cart, setCart] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [newRating, setNewRating] = useState(5);
    const [userEmail, setUserEmail] = useState('');
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);

    // Fetch product details from API
    const fetchProduct = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/products/${productName}`);
            setProduct(response.data);
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
    };
    console.log('Product Name:', productName);

    
    // const fetchProduct = async () => {
    //     try {
    //         const response = await axios.get(`${API_BASE_URL}/products/${productId}`);
    //         setProduct(response.data);
    //     } catch (error) {
    //         console.error('Error fetching product details:', error);
    //     }
    // };

    // Fetch product reviews
    const fetchReviews = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/products/${productName}/reviews`);
            setReviews(response.data);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    // Load cart from localStorage on initial load
    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCart(storedCart);
    }, []);

    // Fetch product and reviews when productId changes
    useEffect(() => {
        fetchProduct();
        fetchReviews();
    }, [productId]);

    // Handle quantity change
    const handleQuantityChange = (event) => {
        const value = event.target.value;
        if (value > 0) {
            setQuantity(Number(value));
        }
    };

    // Add product to cart
    const handleAddToCart = () => {
        const existingItem = cart.find((item) => item.id === productId);
        let updatedCart;

        if (existingItem) {
            updatedCart = cart.map((item) =>
                item.id === productId ? { ...item, quantity: item.quantity + quantity } : item
            );
        } else {
            updatedCart = [...cart, { ...product, quantity }];
        }

        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        alert('Successfully added to cart!');
    };

    // Navigate to cart page
    const navigateToCart = () => {
        navigate('/cart');
    };

    const getEmotion = (rating) => {
        const emotions = {
            1: 'Not so Good 😞',
            2: 'Well... 😕',
            3: 'Okay 😐',
            4: 'Good 🙂',
            5: 'Excellent 😄',
        };
        return emotions[rating] || '';
    };
    

    // Add review
    const addReview = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email format check
        if (!emailRegex.test(userEmail.trim())) {
            alert('Please enter a valid email address.');
            return;
        }
        if (!newComment.trim()) {
            alert('Please enter your comment.');
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/products/${productName}/reviews`, {
                comment: newComment,
                rating: newRating,
                email: userEmail,
            });
            setReviews([response.data, ...reviews]);
            setNewComment('');
            setNewRating(5);
            setUserEmail('');
            alert('Review added successfully!');
        } catch (error) {
            console.error('Error adding review:', error);
            alert('Failed to add review. Please try again.');
        }
    };

    if (!product) return <div>Loading...</div>;

    return (
        <div>
            <Header />
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-10 relative">
                <div className="bg-white shadow-lg rounded-lg p-8 lg:p-10 flex flex-col lg:flex-row">
                    <div className="flex-shrink-0 w-full lg:w-1/2 mb-6 lg:mb-0">
                        <img
                            src={`${API_BASE_URL}/media/${product.image}`}
                            alt={product.name}
                            className="w-full rounded-lg shadow-lg hover:scale-105 transform transition-transform duration-300"
                        />
                    </div>
                    <div className="flex flex-col w-full lg:w-1/2 lg:pl-10 text-gray-800">
                        <h2 className="text-4xl font-bold text-blue-700 mb-4">{product.name}</h2>
                        <p className="text-3xl font-bold text-gray-900 mb-6">${product.price}</p>

                        {/* Quantity Input */}
                        <div className="flex items-center mb-6">
                            <button
                                onClick={() => setQuantity(quantity - 1)}
                                className="px-4 py-2 bg-gray-200 text-gray-600 rounded-l"
                                disabled={quantity <= 1}
                            >
                                -
                            </button>
                            <input
                                type="number"
                                value={quantity}
                                onChange={handleQuantityChange}
                                className="w-16 text-center border-2 border-gray-300 rounded-md"
                            />
                            <button
                                onClick={() => setQuantity(quantity + 1)}
                                className="px-4 py-2 bg-gray-200 text-gray-600 rounded-r"
                            >
                                +
                            </button>
                        </div>

                        <button
                            onClick={handleAddToCart}
                            className="bg-green-500 hover:bg-green-600 text-white font-semibold text-lg px-8 py-3 rounded-lg shadow-lg mb-6 transition duration-200 transform hover:scale-105"
                        >
                            Add to Cart
                        </button>

                        <p className="text-lg text-gray-600 mb-6">{product.short_description}</p>

                        <div className="mt-6">
                            <h3 className="text-2xl font-semibold text-blue-600 mb-2">Description</h3>
                            <p className="text-gray-700 leading-relaxed mb-6">{product.description}</p>
                        </div>

                        <div className="mb-6">
                            <h3 className="text-2xl font-semibold text-blue-600 mb-2">About</h3>
                            <p className="text-gray-700 leading-relaxed">{product.about}</p>
                        </div>

                        {product.video && (
                            <div className="mt-8">
                                <h3 className="text-2xl font-semibold text-blue-600 mb-2">Product Video</h3>
                                <video width="100%" controls className="rounded-lg shadow-lg">
                                    <source src={`${API_BASE_URL}/media/${product.video}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        )}
                    </div>
                </div>

                {/* Cart Icon in the Bottom Right Corner */}
                <div className="fixed bottom-4 right-4 lg:bottom-8 lg:right-8 z-50">
                    <button
                        onClick={navigateToCart}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold text-xl p-4 rounded-full shadow-lg transition duration-200 transform hover:scale-105"
                    >
                        <ShoppingCartIcon className="h-6 w-6" />
                    </button>
                </div>

                {/* Sticky Add to Cart Button */}
                <div className="fixed bottom-4 right-24 lg:bottom-8 lg:right-24 z-50">
                    <button
                        onClick={handleAddToCart}
                        className="bg-green-500 hover:bg-green-600 text-white font-semibold text-lg px-8 py-3 rounded-full shadow-lg transition duration-200 transform hover:scale-105"
                    >
                        Add to Cart
                    </button>
                </div>
            </div>

            <div className="mt-16 bg-gray-50 py-10 px-6 lg:px-12 rounded-lg shadow-lg">
    <h3 className="text-3xl font-bold text-blue-700 mb-4">Reviews</h3>

    {/* Add Review Form */}
    <div className="mb-6 bg-gray-50 p-6 rounded-lg shadow">
        <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Leave a comment..."
            className="w-full p-4 border rounded-md mb-3 focus:outline-blue-500"
        />
        <div className="flex items-center gap-4 mb-3">
            <label className="text-gray-700">Rating:</label>
            <div className="flex items-center">
                {[1, 2, 3, 4, 5].map((rating) => (
                    <div
                        key={rating}
                        onClick={() => setNewRating(rating)}
                        onMouseEnter={() => setNewRating(rating)}
                        className={`cursor-pointer ${
                            newRating >= rating ? 'text-yellow-400' : 'text-gray-300'
                        } transition duration-200`}
                        title={getEmotion(rating)}
                    >
                        <StarIcon className="h-6 w-6" />
                    </div>
                ))}
            </div>
        </div>
        <div className="mb-3">
            <input
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full p-4 border rounded-md focus:outline-blue-500"
            />
        </div>
        <button
            onClick={addReview}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
        >
            Submit Review
        </button>
    </div>

    {/* Display Reviews */}
    <div className="space-y-6">
    {reviews && reviews.length > 0 ? (
        reviews.map((review) => {
            const rating = Math.min(Math.max(Number(review.rating) || 0, 0), 5); // Ensure rating is between 0 and 5
            return (
                <div
                    key={review.id}
                    className="p-6 border rounded-md bg-white shadow-sm flex flex-col gap-4 sm:gap-0 sm:flex-row sm:items-center justify-between"
                >
                    <div className="flex-1">
                        {/* Review Comment */}
                        <p className="text-lg font-semibold text-gray-800">{review.comment}</p>

                        {/* Star Rating */}
                        <div className="flex items-center mt-2">
                            {[...Array(rating)].map((_, i) => (
                                <StarIcon key={`filled-${review.id}-${i}`} className="h-5 w-5 text-yellow-400" />
                            ))}
                            {[...Array(5 - rating)].map((_, i) => (
                                <StarIcon key={`empty-${review.id}-${i}`} className="h-5 w-5 text-gray-300" />
                            ))}
                        </div>

                        {/* Reviewer Info */}
                        <p className="text-gray-500 text-sm mt-2">
                            By {review.email || "Anonymous"} on{' '}
                            {review.created_at ? new Date(review.created_at).toLocaleDateString() : "N/A"}
                        </p>
                    </div>
                </div>
            );
        })
    ) : (
        <p className="text-gray-500">No reviews yet. Be the first to leave a review!</p>
    )}
</div>

                </div>
        </div>
    );
};

export default ProductDetail;
