import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AdminNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); 
    navigate('/'); 
  };

  return (
    <nav className="bg-gray-800 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Title */}
          <Link to="/admin/home" className="text-xl font-semibold">
            Admin Dashboard
          </Link>
          
          {/* Hamburger Icon for Mobile */}
          <div className="flex md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-300 hover:text-white focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex md:space-x-8">
            <Link to="/admin/products" className="py-2 px-4 hover:bg-gray-700 rounded">
              Products
            </Link>
            <Link to="/admin/settings" className="py-2 px-4 hover:bg-gray-700 rounded">
              Settings
            </Link>
            <Link to="/admin/contacts" className="py-2 px-4 hover:bg-gray-700 rounded">
              Contacts
            </Link>
            <Link to="/admin/orders" className="py-2 px-4 hover:bg-gray-700 rounded">
              Orders
            </Link>
            <button
              onClick={handleLogout}
              className="py-2 px-4 hover:bg-gray-700 rounded"
            >
              Logout
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-700">
          <ul className="flex flex-col items-start px-4 py-2 space-y-2">
            <li>
              <Link to="/admin/products" className="block py-2 px-4 text-white hover:bg-gray-600 rounded" onClick={() => setIsOpen(false)}>
                Products
              </Link>
            </li>
            <li>
              <Link to="/admin/settings" className="block py-2 px-4 text-white hover:bg-gray-600 rounded" onClick={() => setIsOpen(false)}>
                Settings
              </Link>
            </li>
            <li>
              <Link to="/admin/contacts" className="block py-2 px-4 text-white hover:bg-gray-600 rounded" onClick={() => setIsOpen(false)}>
                Contacts
              </Link>
            </li>
            <li>
              <Link to="/admin/orders" className="block py-2 px-4 text-white hover:bg-gray-600 rounded" onClick={() => setIsOpen(false)}>
                Orders
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  handleLogout();
                  setIsOpen(false);
                }}
                className="block w-full text-left py-2 px-4 text-white hover:bg-gray-600 rounded"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default AdminNavBar;
