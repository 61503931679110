import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="md:flex md:justify-between">
          {/* Logo Section */}
          <div className="mb-6 md:mb-0">
            <h2 className="text-2xl font-bold">Timardor Global Resources</h2>
            <p className="text-gray-400 mt-2">Specializing in the sales of medical equipment globally.</p>
          </div>

          {/* Links Section */}
          <div className="mb-6 md:mb-0">
            <h3 className="text-lg font-semibold">Quick Links</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="/about" className="hover:text-gray-300">About Us</a></li>
              <li><a href="/contact" className="hover:text-gray-300">Contact Us</a></li>
            </ul>
          </div>

          {/* Social Media Section */}
          <div>
            <h3 className="text-lg font-semibold">Follow Us</h3>
            <ul className="mt-4 flex space-x-4">
            <li><a href="https://wa.me/2349019889668" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">Whatsapp</a></li>
            <li><a href="https://x.com/Timar_world" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">Twitter</a></li>
            <li><a href="https://www.instagram.com/timarworld" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">Instagram</a></li>
            <li><a href="https://www.linkedin.com/in/timarworld018/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">LinkedIn</a></li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-700 mt-8 pt-4 text-center">
          <p>&copy; {new Date().getFullYear()} Timardor Global Resources Ltd. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
