import React, { useState, useEffect } from 'react';
import AdminNavBar from './AdminNav';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import Modal from './Modal';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null); // Store selected order details
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
      setStatus('Authorization token not found. Please log in again.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setOrders(data.orders);
      } else {
        const errorData = await response.json();
        setStatus(errorData.message || 'Failed to fetch orders.');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setStatus('An error occurred. Please try again later.');
    }
  };


  const handleMarkAsCompleted = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/orders/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setOrders(orders.map((order) =>
          order.id === id ? { ...order, isCompleted: true, completed_at: new Date().toISOString() } : order
        ));
        setStatus('Order marked as completed.');
      } else {
        const errorData = await response.json();
        setStatus(errorData.message || 'Failed to mark order as completed.');
      }
    } catch (error) {
      console.error('Error marking order as completed:', error);
      setStatus('An error occurred while updating the order.');
    }
  };

  const handleViewOrderDetails = (orderId) => {
    const selectedOrderDetails = orders.find((order) => order.id === orderId);
    setSelectedOrder(selectedOrderDetails);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <AdminNavBar />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <h1 className="text-3xl md:text-4xl font-extrabold text-center text-blue-700 mb-6 md:mb-8">
          Admin Orders
        </h1>

        {status && <p className="text-green-600 text-center mb-4">{status}</p>}

        <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
          <h2 className="text-xl md:text-2xl font-semibold text-blue-600 mb-4 md:mb-6">
            Orders List
          </h2>

          {orders.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse table-auto">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-2 text-left text-sm md:text-base text-gray-700">Customer Name</th>
                    <th className="px-4 py-2 text-left text-sm md:text-base text-gray-700">Customer Email</th>
                    <th className="px-4 py-2 text-left text-sm md:text-base text-gray-700">Phone Number</th>
                    <th className="px-4 py-2 text-left text-sm md:text-base text-gray-700">Total Amount</th>
                    <th className="px-4 py-2 text-left text-sm md:text-base text-gray-700">Status</th>
                    <th className="px-4 py-2 text-left text-sm md:text-base text-gray-700">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id} className="hover:bg-gray-50">
                      <td className="px-4 py-2 text-sm md:text-base">{order.customer_name}</td>
                      <td className="px-4 py-2 text-sm md:text-base">{order.customer_email}</td>
                      <td className="px-4 py-2 text-sm md:text-base">{order.phone_number}</td>
                      <td className="px-4 py-2 text-sm md:text-base">₦{order.total_amount.toFixed(2)}</td>
                      <td className="px-4 py-2 text-sm md:text-base">
                        {order.isCompleted ? 'Completed' : 'Pending'}
                      </td>
                      <td className="px-4 py-2 text-sm md:text-base space-y-2 flex flex-col sm:flex-row sm:space-y-0 sm:space-x-2">
                          <button
                            onClick={() => handleViewOrderDetails(order.id)}
                            className="bg-blue-600 text-white px-3 py-2 rounded-md hover:bg-blue-700 transition text-xs md:text-sm"
                            >View Details
                          </button>
                          {!order.isCompleted && (
                            <button
                            onClick={() => handleMarkAsCompleted(order.id)}
                            className="bg-green-600 text-white px-3 py-2 rounded-md hover:bg-green-700 transition text-xs md:text-sm"
                            >
                              Mark as Completed
                            </button>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-600 text-center">No orders available.</p>
          )}
        </div>
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          {selectedOrder && (
            <div className="p-4 sm:p-6">
              <h2 className="text-xl md:text-2xl font-bold mb-4">Order Details</h2>
              <div className="space-y-2 text-sm md:text-base">
                <p><strong>ID:</strong> {selectedOrder.id}</p>
                <p><strong>Customer Name:</strong> {selectedOrder.customer_name}</p>
                <p><strong>Email:</strong> {selectedOrder.customer_email}</p>
                <p><strong>City Name:</strong> {selectedOrder.delivery_address}</p>
                <p><strong>Address:</strong> {selectedOrder.address}</p>
                <p><strong>State:</strong> {selectedOrder.state}</p>
                <p><strong>Country:</strong> {selectedOrder.country}</p>
                <p><strong>Phone Number:</strong> {selectedOrder.phone_number}</p>
                {selectedOrder.alternative_number && (
                  <p><strong>Alternative Number:</strong> {selectedOrder.alternative_number}</p>
                )}
                <p><strong>Local Government:</strong> {selectedOrder.local_government}</p>
                {selectedOrder.comments && (
                  <p><strong>Comments:</strong> {selectedOrder.comments}</p>
                )}
                <p><strong>Total Amount:</strong> ₦{selectedOrder.total_amount.toFixed(2)}</p>
                <p><strong>Discounted Amount:</strong> ₦{selectedOrder.discounted_amount.toFixed(2)}</p>
                <p><strong>Payment Reference:</strong> {selectedOrder.payment_reference}</p>
                <p><strong>Created At:</strong> {new Date(selectedOrder.created_at).toLocaleString()}</p>
                {selectedOrder.isCompleted && (
                  <p><strong>Completed At:</strong> {new Date(selectedOrder.completed_at).toLocaleString()}</p>
                )}
                <h3 className="text-lg font-semibold mt-4">Items</h3>
                <ul className="list-disc pl-5 text-sm md:text-base">
                  {selectedOrder.items.map((item, index) => (
                    <li key={index}>
                      {item.name} - Quantity: {item.quantity} - ₦{item.price.toFixed(2)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default AdminOrders;
