import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
import axios from 'axios';
import { API_BASE_URL } from '../config';

const Checkout = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [alternativeNumber, setAlternativeNumber] = useState('');
  const [address, setAddress] = useState('');
  const [delivery_address, setdAddress] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [localGovernment, setLocalGovernment] = useState('');
  const [comments, setComments] = useState('');
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        console.log('Fetching exchange rate...');
        const response = await axios.get(`${API_BASE_URL}/api/exchange-rate`);
        console.log('Exchange rate fetched successfully:', response.data);
        setExchangeRate(response.data.usd_ngn);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
        setExchangeRate(1); // Fallback exchange rate
      }
    };

    fetchExchangeRate();
  }, []);

  useEffect(() => {
    if (exchangeRate !== null) {
      console.log('Calculating totals with exchange rate:', exchangeRate);
      const initialTotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
      const initialDiscounted = initialTotal * 0.95;
      setTotalAmount(initialTotal * exchangeRate);
      setDiscountedAmount(initialDiscounted * exchangeRate);
      console.log('Totals calculated:', { initialTotal, initialDiscounted });
    }
  }, [cart, exchangeRate]);

  const handlePaymentSuccess = async () => {
    alert('Payment Successful!');
    console.log('Payment successful, sending order details to backend...');
    localStorage.removeItem('cart');

    try {
      const orderData = {
        customer_name: name,
        customer_email: email,
        phone_number: phoneNumber,
        alternative_number: alternativeNumber,
        address,
        delivery_address,
        state,
        country,
        local_government: localGovernment,
        comments,
        items: cart,
        total_amount: totalAmount,
        discounted_amount: discountedAmount,
        payment_reference: paystackConfig.reference,
      };
      console.log('Order data:', orderData);

      const response = await axios.post(`${API_BASE_URL}/orders`, orderData);
      console.log('Order saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving order:', error.response ? error.response.data : error.message);
    }

    navigate('/processing');
  };

  const handlePaymentClose = () => {
    alert('Payment was not completed');
    console.log('Payment window closed without completion.');
  };

  const paystackConfig = {
    email,
    amount: discountedAmount * 100,
    publicKey: 'pk_live_2c56a22cea48e53587f18143e5f800f5401f1a7e',
    onSuccess: handlePaymentSuccess,
    onClose: handlePaymentClose,
    reference: `order_${Date.now()}`,
    metadata: {
      customer_name: name,
      customer_email: email,
      phone_number: phoneNumber,
      alternative_number: alternativeNumber,
      address,
      delivery_address,
      state,
      country,
      local_government: localGovernment,
      comments,
      items: cart.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
    },
  };

  const handleCheckout = (e) => {
    e.preventDefault();
    console.log('Checkout form submitted...');
    if (!name || !email || !phoneNumber || !address  || !delivery_address || !state || !country || !localGovernment) {
      setErrorMessage('Please fill in all the required fields.');
      console.error('Validation error: Missing required fields.');
      return;
    }
    setErrorMessage('');
    console.log('All required fields are filled. Ready for payment.');
  };

  if (cart.length === 0) {
    console.warn('Cart is empty.');
    return (
      <div className="container mx-auto px-4 py-8 bg-white shadow-lg rounded-lg text-center">
        <h2 className="text-4xl font-bold mb-6 text-gray-800">Your Cart is Empty</h2>
        <Link to="/" className="text-blue-600 hover:underline">
          Go back to Shop
        </Link>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-4xl font-bold mb-6 text-center text-gray-800">Checkout</h2>
      {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
      <form onSubmit={handleCheckout} className="grid gap-6 max-w-md mx-auto bg-gray-50 p-6 rounded-lg shadow-md">
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <input
          type="tel"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <input
          type="text"
          placeholder="Alternative Number (Optional)"
          value={alternativeNumber}
          onChange={(e) => setAlternativeNumber(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        <input
          type="text"
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <input
          type="text"
          placeholder="City"
          value={delivery_address}
          onChange={(e) => setdAddress(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <input
          type="text"
          placeholder="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <input
          type="text"
          placeholder="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <input
          type="text"
          placeholder="Local Government"
          value={localGovernment}
          onChange={(e) => setLocalGovernment(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <textarea
          placeholder="Comments (Optional)"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        <div className="text-center mt-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-800">Total Amount: ₦{totalAmount.toFixed(2)}</h3>
          <h3 className="text-lg font-semibold text-green-600 mb-4">
            Discounted Amount: ₦{discountedAmount.toFixed(2)}
          </h3>
          <PaystackButton
            {...paystackConfig}
            className={`bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-200 shadow-md ${
              !name || !email || !phoneNumber || !address || !state || !country || !localGovernment
                ? 'opacity-50 cursor-not-allowed'
                : ''
            }`}
            text="Pay with Paystack"
            disabled={!name || !email || !phoneNumber || !address || !state || !country || !localGovernment}
          />
        </div>
      </form>
    </div>
  );
};

export default Checkout;
