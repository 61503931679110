import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import { API_BASE_URL } from '../config';

const Catalogue = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch products from the API
    const fetchProducts = async () => {
        try {
            setLoading(true); // Start loading
            const response = await axios.get(`${API_BASE_URL}/products`);
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <div>
            <Header />
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-10">
                <h2 className="text-4xl font-extrabold text-center text-blue-600 mb-8">Timardor Global Resources Catalogue</h2>
                <p className="text-lg text-center text-gray-600 mb-6">
                    Welcome to our catalogue. Here, you can explore our wide range of medical equipment and resources that we offer globally.
                </p>

                {/* Loading Spinner */}
                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="spinner-border animate-spin inline-block w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    /* Product List */
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                        {products.map((product) => (
                            <div key={product.id} className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
                                <img
                                    src={`${API_BASE_URL}/media/${product.image}`}
                                    alt={product.name}
                                    className="w-full h-48 object-cover mb-4 rounded"
                                />
                                <h3 className="text-lg font-bold">{product.name}</h3>
                                <p className="text-gray-600 mt-1">${product.price}</p>
                                <p className="text-gray-700 mt-2">{product.shortDescription}</p>
                            </div>
                        ))}
                    </div>
                )}

                {/* Download Button */}
                <div className="text-center mt-8">
                    <a 
                        href={`${process.env.PUBLIC_URL}/catalogue.pdf`} 
                        download 
                        className="inline-block mt-4 px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow hover:bg-blue-700 transition"
                    >
                        Download Catalogue
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Catalogue;
